import Layout from 'gatsby-theme-carbon/src/components/Layout';
import Main from 'gatsby-theme-carbon/src/components/Main';
import ImageGallery from 'gatsby-theme-carbon/src/components/ImageGallery/ImageGallery';
import ImageGalleryImage from 'gatsby-theme-carbon/src/components/ImageGallery/ImageGalleryImage';
import { Column, FourOhFour, GifPlayer, ImageCard, Row } from 'gatsby-theme-carbon';
import Utils from 'gatsby-theme-carbon/src/components/Utils';
import React, { useState } from 'react';
import { Link } from 'gatsby';
import {
    Accordion,
    AccordionItem,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    DataTable, Modal,
    StructuredListBody,
    StructuredListCell,
    StructuredListHead,
    StructuredListRow,
    StructuredListWrapper,
    Table,
    TableBatchAction,
    TableBatchActions,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    TableSelectAll,
    TableSelectRow,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch, TextArea, TextInput
} from "@carbon/react";
import { MailAll32 } from '@carbon/icons-react';
import { useContext } from 'react';
import BreadcrubmsContext from '../../util/context/BreadcrubmsContext';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PageHeader from 'gatsby-theme-carbon/src/components/PageHeader';

import axios from 'axios';



const API_URL = "https://api.industrator.ru/v1";

const getProductData = async (
    slug
) => {
    const { data: products } = await axios.get(
        `${API_URL}/products?filter=%7B%22slug%22%3A%22${slug}%22%7D&range=%5B%5D&sort=%7B%7D`
    )
    return products[0]
}

const getProductsAndGetServerData = async (
    {
        params: { slug = 'test' },
    }
) => {
    const product = await getProductData(slug);
    return {
        props: {
            product,
        },
    }
}

const getErrorServerData = () => {
    return {
        headers: {
            status: 500,
        },
        props: {},
    }
}

export async function getServerData(context) {
    // console.log(context)
    try {
        return getProductsAndGetServerData(context)
    } catch (error) {
        console.error(error)
        return getErrorServerData()
    }
}

const SSRPage = ({ serverData: { product } }) => {
    // console.log(data);

    const industratorProduct = product;
    const [sentDialogOpen, sentDialogOpenSet] = useState(false);
    const [selectedLegalEntities, selectedLegalEntitiesSet] = useState([]);
    const {
        isBrands,
        path
    } = useContext(BreadcrubmsContext);

    if (!product) {
        return <FourOhFour />;
    }

    const getImages = (images) => {
        return images.map(url => {
            // const { url } = _image;
            if (url) {
                if (url.startsWith('http')) {
                    return url
                } else {
                    return `https://media.industrator.ru${url}`
                }
            }
            return null;
        })

    }
    let minPrice = '';
    let maxPrice = '';

    // const images = getImages(datrunProduct.images);
    // const mainImage = images.length ? images[0] : '';
    // console.log(images)
    let images = industratorProduct.data?.localImages?.map(image => {
        // console.log(image)
        if (!image.childImageSharp && image.extension) {
            return image.publicURL;
        }
        else {
            return getImage(image)
        }
    });
    if (!images?.length) {
        images = ['/images/empty.png'];
    }
    if (!industratorProduct.description) {
        industratorProduct.description = 'Описание скоро появится';
    }
    // This is the same as:
    // const image = data?.avatar?.childImageSharp?.gatsbyImageData
    return (
        <Layout
            homepage={false}
            theme={"white"}
            titleType='prepend'
            pageTitle={`Купить ${industratorProduct.name} ${industratorProduct.brand?.article} ${industratorProduct.article} технические характеристики`}
            pageDescription={`Купить ${industratorProduct.name} ${industratorProduct.brand?.article} ${industratorProduct.article} технические характеристики`}
            pageKeywords={`Купить ${industratorProduct.name} ${industratorProduct.brand?.article} ${industratorProduct.article} технические характеристики`}
            pageData={
                {
                    type: 'product',
                    brand: `${industratorProduct.brand?.article}`,
                    name: `${industratorProduct.name} ${industratorProduct.article}`,
                    article: `${industratorProduct.article}`,
                    // image: `${mainImage}`,
                    description: `${industratorProduct.description}`,
                    lowPrice: `0`,
                    highPrice: `0`,
                    offerCount: `${industratorProduct.data?.sellData?.le?.length}`
                }
            }
        >
            <PageHeader
                title={`${industratorProduct.article}`}
                label="label"
                tabs={[]}
                theme={'dark'}
            />
            <Main padded>

                <Row>
                    <Column>
                        <Breadcrumb noTrailingSlash>
                            <BreadcrumbItem href="/">
                                <Link to={'/'}>Главная
                                </Link>
                            </BreadcrumbItem>
                            {isBrands && (
                                <>
                                    <BreadcrumbItem href="/brands">
                                        <Link to={'/brands'}>Бренды
                                        </Link>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem href={`/brand/${industratorProduct.brand?.slug ? industratorProduct.brand?.slug : '/'}`}>
                                        <Link
                                            to={`/brand/${industratorProduct.brand?.slug ? industratorProduct.brand?.slug : '/'}`}>{industratorProduct.brand?.article}</Link>
                                    </BreadcrumbItem>
                                </>
                            )}
                            {path?.name &&
                                (<>
                                    <BreadcrumbItem href={`${path.url}`}>
                                        <Link to={`${path.url}`}>{`${path.name}`}
                                        </Link>
                                    </BreadcrumbItem>
                                </>)}
                            <BreadcrumbItem isCurrentPage>
                                {industratorProduct.article}
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </Column>
                </Row>

                <Row>
                    <Column colSm={12} colLg={8}>
                        <h1 className={'product-page--header__title'}>{industratorProduct.name} {industratorProduct.brand?.article} {industratorProduct.article}</h1>
                        <h2>{industratorProduct.brand?.article}</h2>
                        {images?.length ? (

                            <Row className="image-card-group">
                                <ImageGallery>
                                    {images.map((image, idx) => {
                                        if (image.layout) {
                                            return (

                                                <ImageGalleryImage alt="Image" title={industratorProduct.article} col={8}>
                                                    {idx === 0 ? <GatsbyImage image={image} alt={industratorProduct.article} /> :
                                                        <GatsbyImage image={image} alt={industratorProduct.article} />}
                                                </ImageGalleryImage>

                                            )
                                        } else {
                                            return (
                                                <GifPlayer >
                                                    <img src={image} width="300px" />
                                                    <img src={image} width="300px" />
                                                </GifPlayer>
                                            )
                                        }
                                    })}
                                </ImageGallery>

                            </Row>
                        ) : ""}
                        <Row>
                            <h2 className='product-page--description__title'>Описание</h2>
                        </Row>
                        <Row>
                            <p className="product-page--description__body" dangerouslySetInnerHTML={{ __html: industratorProduct.description }} />
                        </Row>


                        {industratorProduct.tech_params && (
                            <Row>
                                <h2>Технические характеристики</h2>
                                {/* <Accordion size="md" align={"start"}> */}
                                {/* {industratorProduct.tech_params?.map((td, i) => { */}
                                {/* return ( */}
                                {/* // <AccordionItem key={i} */}
                                {/* //     title={td.title ? td.title : td.name ? td.name : "Общие данные"}> */}

                                <StructuredListWrapper ariaLabel="Structured list">
                                    <StructuredListHead>
                                        <StructuredListRow
                                            head
                                            tabIndex={0}
                                        >
                                            <StructuredListCell head>
                                                Название параметра
                                            </StructuredListCell>
                                            <StructuredListCell head>
                                                Значение параметра
                                            </StructuredListCell>
                                        </StructuredListRow>
                                    </StructuredListHead>
                                    <StructuredListBody>
                                        {industratorProduct.tech_params?.map((param, idx) => {
                                            return (
                                                <StructuredListRow key={idx} tabIndex={idx}>
                                                    <StructuredListCell>
                                                        {param.name}
                                                    </StructuredListCell>
                                                    <StructuredListCell>
                                                        {param.value} {param.unit}
                                                    </StructuredListCell>
                                                </StructuredListRow>
                                            )
                                        })}
                                    </StructuredListBody>
                                </StructuredListWrapper>

                                {/* // </AccordionItem> */}
                                {/* ) */}
                                {/* })} */}
                                {/* </Accordion> */}
                            </Row>
                        )}
                    </Column>
                    <Column colSm={12} colLg={4}>
                        <h2 className={'product-page--header__title'}>{industratorProduct.brand?.article}</h2>
                        <GifPlayer className={'product-page--header__title'}>
                            <img src={images[0]} width="200px" />
                            <img src={images[0]} width="200px" />
                        </GifPlayer>
                        <Button className={'product-page--header__title'} onClick={(e) => {
                            sentDialogOpenSet(true)
                            console.log(e)
                        }}>Купить</Button>
                    </Column>
                </Row>
                {industratorProduct.data?.sellData?.le?.length > 0 && (
                    <Row>
                        <Column>
                            {/*<H3>Где купить?</H3>*/}
                            <DataTable locale="ru" rows={industratorProduct.data?.sellData?.le?.map((entity) => {
                                entity['deliv'] = "от 2 недель";
                                // entity.current_price = entity.current_price + " руб"
                                return { ...entity, ...entity.entity }
                            })} headers={[
                                {
                                    key: `name`,
                                    header: 'Поставщик'
                                },
                                {
                                    key: 'current_price',
                                    header: 'Цена (без НДС)'
                                },
                                {
                                    key: 'min_count',
                                    header: 'Мин. кол-во'
                                },
                                {
                                    key: 'deliv',
                                    header: 'Срок поставки'
                                },
                                // {
                                //     key: 'phone',
                                //     header: 'Телефон'
                                // }
                            ]}>
                                {({
                                    rows,
                                    headers,
                                    getHeaderProps,
                                    getRowProps,
                                    getSelectionProps,
                                    getBatchActionProps,
                                    onInputChange,
                                    selectedRows,
                                }) => (
                                    <TableContainer title="Где купить?" description="Выберите поставщиков кому отправить запрос">
                                        <TableToolbar>
                                            <TableBatchActions translateWithId={(id, state) => {
                                                if (id === 'carbon.table.batch.cancel') {
                                                    return 'Отмена';
                                                }
                                                return `${state.totalSelected} выбрано`;
                                            }} {...getBatchActionProps()}>
                                                <TableBatchAction
                                                    tabIndex={getBatchActionProps().shouldShowBatchActions ? 0 : -1}
                                                    renderIcon={MailAll32}
                                                    onClick={(e) => {
                                                        selectedLegalEntitiesSet(selectedRows);
                                                        sentDialogOpenSet(true)
                                                    }}
                                                >
                                                    Отправить запрос
                                                </TableBatchAction>
                                            </TableBatchActions>
                                            <TableToolbarContent>
                                                <TableToolbarSearch expanded={true}
                                                    tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
                                                    onChange={onInputChange}
                                                />
                                            </TableToolbarContent>
                                        </TableToolbar>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableSelectAll {...getSelectionProps()} />
                                                    {headers.map((header) => {
                                                        // console.log(header)
                                                        return (
                                                            <TableHeader {...getHeaderProps({ header })}>
                                                                {header.header}
                                                            </TableHeader>
                                                        )
                                                    })}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => {
                                                    // console.log(row.cells)
                                                    return (
                                                        <TableRow {...getRowProps({ row })}>
                                                            <TableSelectRow {...getSelectionProps({ row })} />
                                                            {row.cells.map((cell) => (
                                                                <TableCell key={cell.id}>{cell.value}</TableCell>
                                                            ))}
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </DataTable>


                        </Column>
                    </Row>)}
                <Modal
                    open={sentDialogOpen}
                    hasForm={true}
                    size={'md'}
                    modalHeading="Отправить запрос выбранным поставщикам"
                    modalLabel="Запрос"
                    primaryButtonText="Отправить запрос"
                    secondaryButtonText="Отмена"
                    onRequestClose={() => {
                        sentDialogOpenSet(false);
                        selectedLegalEntitiesSet([]);
                    }}
                    onRequestSubmit={() => {
                       alert("Произошла ошибка на стороне сайта")
                    }}
                    className="product-page--request-form"
                >

                    <TextInput data-modal-primary-focus labelText={'Имя'} id={'name'}
                        type={'text'}
                        placeholder={'Ваше имя'} />
                    <TextInput labelText={'Компания'} id={'name'} type={'text'}
                        placeholder={'Название компании'} />

                    <TextInput labelText={'E-mail'} id={'email'}
                        type={'email'}
                        placeholder={'Ваш e-mail'} />
                    <TextInput labelText={'Телефон'} id={'email'} type={'email'}
                        placeholder={'Ваш телефон'} />

                    <TextArea labelText={'Запрос'} placeholder={'Текст запроса'} />

                </Modal>
            </Main>
            <Utils />
        </Layout>
    )
};
export default SSRPage;